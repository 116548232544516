import { createApp } from 'vue';
import JobsearchNewJobsearchSwitch from 'JIX/components/jobsearch/JobsearchNewJobsearchSwitch.vue';

const stash = window.Stash['jobsearch/new_jobsearch_switch_mobile'];

const newJobsearchSwitchMobileContainer = document.getElementById('new-jobsearch-switch-mobile');
if (newJobsearchSwitchMobileContainer) {
    createApp(JobsearchNewJobsearchSwitch, {
        seenFeatureTip: Boolean(stash.new_jobsearch.seen_feature_tip)
    }).mount(newJobsearchSwitchMobileContainer);
}